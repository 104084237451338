<template>
  <div class="profile-header">
    <v-row
      justify="center"
    >
      <v-avatar
        size="250"
      >
        <img
          :src="profile.avatar_display_url"
          :alt="profile.user + ' avatar'"
        >
      </v-avatar>
    </v-row>
    <v-row>
      <v-col class="profile-name">
        <div
          v-if="profile.full_name"
          class="headline"
        >
          {{ profile.full_name }}
        </div>
        <div class="title">
          {{ profile.user }}
        </div>
        <div
          v-if="profile.home_region"
          class="subtitle-1"
        >
          {{ profile.home_region.name }}
        </div>
      </v-col>
      <v-col class="text-right">
        <div class="title">
          {{ profile.points_earned }}
        </div>
        <div>
          points
        </div>
      </v-col>
    </v-row>
    <div
      v-if="showMeta"
      class="profile-meta mb-3"
    >
      <v-divider />
      <v-row>
        <v-col>
          Join Date
        </v-col>
        <v-col class="text-right">
          {{ profile.created|formatDate }}
        </v-col>
      </v-row>
      <v-divider />
      <v-row v-if="team">
        <v-col>
          Team
        </v-col>
        <v-col class="text-right">
          <router-link :to="{ name: 'Team', params: { id: team.id }}">
            {{ team.name }}
          </router-link>
        </v-col>
      </v-row>
      <v-divider v-if="team" />
      <v-row v-if="profile.email">
        <v-col>
          Email
        </v-col>
        <v-col class="text-right">
          {{ profile.email }}
        </v-col>
      </v-row>
      <v-divider v-if="profile.email" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profile: {
      type: Object,
      required: true,
    },
    showMeta: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    team() {
      if (this.profile.teams.length) {
        return this.profile.teams[0]
      }
      return null
    },
  },
}
</script>
