<template>
  <v-card>
    <v-card-title>
      Evidence Locker
    </v-card-title>
    <Loading
      v-if="loading"
      :loading="loading"
      message="Loading Evidence"
    />
    <v-card-text v-if="error">
      <v-alert type="error">
        Failed to load evidence.
      </v-alert>
    </v-card-text>
    <v-card-text v-else-if="!loading && !error && evidenceList.length < 1">
      This {{ objectType }} does not have any public evidence yet.
    </v-card-text>
    <v-list
      subheader
      max-height="450px"
      class="overflow-y-auto"
    >
      <v-container>
        <v-row :dense="$vuetify.breakpoint.mobile">
          <v-col
            v-for="evidence in evidenceList"
            :key="evidence.id"
            cols="6"
            sm="4"
            xl="3"
          >
            <v-card
              flat
              tile
              max-width="512"
              @click.stop="showEvidence(evidence)"
            >
              <v-img
                :src="evidence.image_display"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          class="text-center"
          align="center"
        >
          <v-col>
            <v-btn
              v-if="nextPage"
              :disabled="loading"
              color="primary"
              @click="loadEvidence"
            >
              Load More
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-list>
    <v-dialog
      v-model="evidenceDialog"
      max-width="512"
    >
      <v-card v-if="selectedEvidence">
        <v-card-title v-if="team">
          <router-link :to="{ name: 'SocialProfile', params: { username: selectedEvidence.profile.user }}">
            <v-avatar
              class="mr-4"
              size="40"
            >
              <img
                :src="selectedEvidence.profile.avatar_thumbnail_url"
                :alt="selectedEvidence.profile.user + ' avatar'"
              >
            </v-avatar>
          </router-link>
          {{ selectedEvidence.profile.user }}
        </v-card-title>
        <v-card-text>
          <v-img :src="selectedEvidence.image_display" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Loading from '@/components/Loading.vue'
import { allianceBaseURL, allianceAPI } from '@/api'

export default {
  components: {
    Loading,
  },
  props: {
    team: {
      type: Number,
      required: false,
      default: null,
    },
    profile: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data: function() {
    return {
      error: false,
      evidenceDialog: false,
      evidenceList: [],
      loading: false,
      selectedEvidence: null,
      nextPage: null,
    }
  },
  computed: {
    objectType() {
      if (this.team) {
        return 'team'
      }
      return 'profile'
    },
    filterQuerystring() {
      let querystring = ''
      if (this.team) {
        querystring += `team=${this.team}&`
      }
      if (this.profile) {
        querystring += `profile=${this.profile}&`
      }
      if (querystring) {
        querystring = `?${querystring}`
      }
      return querystring
    },
  },
  created: function () {
    this.loadEvidence()
  },
  methods: {
    loadEvidence() {
      if (this.filterQuerystring) {
        this.loading = true
        this.error = false
        let url = `/quest/evidence/${this.filterQuerystring}`
        if (this.nextPage) {
          url = this.nextPage
        }
        return allianceAPI.get(url)
          .then((response) => {
            this.evidenceList.push(...response.data.results)
            if (response.data.next) {
              this.nextPage = response.data.next.replace(allianceBaseURL, '')
            } else {
              this.nextPage = null
            }
          })
          .catch(() => this.error = true)
          .finally(() => this.loading = false)
      }
    },
    showEvidence(evidence) {
      this.selectedEvidence = evidence
      this.evidenceDialog = true
    },
  },
}
</script>
