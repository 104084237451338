<template>
  <v-card class="achivements">
    <v-card-title>
      Achievements
    </v-card-title>
    <v-list
      max-height="450px"
      class="overflow-y-auto"
    >
      <v-list-item
        v-for="achievement in achievements"
        :key="achievement.id"
        :to="{ name: 'Challenge', params: { slug: achievement.challenge.slug }}"
        two-line
      >
        <v-list-item-avatar>
          <v-img
            v-if="achievement.current_level.badge_thumbnail_url"
            :src="achievement.current_level.badge_thumbnail_url"
            :alt="achievement.current_level.display_name"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="achievement.challenge.name" />
          <v-list-item-subtitle v-text="achievement.current_level.display_name" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    achievements: {
      type: Array,
      required: true,
    },
  },
}
</script>
